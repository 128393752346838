/* COMMON */
/*body {
    padding-left: 15px;
    padding-right: 15px;
}

hr {
    margin-top: 15px;
    margin-bottom: 15px;
}*/

.fc .section-title {
    margin-top: 5px;
    margin-bottom: 5px;
}

.fc .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 8px 0 0;
}

.fc-widget .form-horizontal .has-feedback .form-control-feedback {
    top: 0;
    right: -15px;
}

.fc-widget .form-horizontal .has-feedback .input-group .form-control-feedback {
    top: 0;
    right: -30px;
}

.fc .flds .btn-link { padding: 0; }

.fc .flds .text {
    padding: 1px;
    vertical-align: middle;
}

.fc .flds .row { padding-bottom: 6px; }

.fc .flds label {
    color: #777777;
    vertical-align: middle;
    margin-top: 0;
    padding-right: 0;
    text-align: right;
}

.fc .flds .divider {
    padding: 1px 5px;
    color: #aaa;
    vertical-align: middle;
}

.fc .flds .fld-label {
    text-align: right;
    font-weight: bold;
    color: #777;
    padding: 0 5px !important;
    vertical-align: middle;
}

.fc .flds .fld-label-vertical {
    text-align: left;
    font-weight: bold;
    color: #777;
    padding: 0 5px !important;
    vertical-align: baseline;
}

.fc .flds .fld-checkbox {
    margin: 0;
    padding-bottom: 5px
}

.fc .flds .fld {
    padding: 0 5px !important;
    vertical-align: middle;
}

.fc .flds .fld .btn-link,
.fc .flds .fld button.btn-link,
.fc .flds .fld a.btn-link { padding: 0 !important; }


.fc .page-header { padding-bottom: 0; }

.fc .page-header h3, .page-header .h3 { margin: 10px 0; }

.fc .pagination { margin: 0; }

.fc .no-padding { padding: 0 !important; }

.fc .sort-head:hover {
    cursor: pointer;
    text-decoration: underline;
}

.fc .sort-head-space {
    color: #ccc;
    text-decoration: underline;
}

.fc .sort-head-disabled { cursor: not-allowed; }

.fc .table-hover tr { cursor: pointer; }

.fc a:hover { cursor: pointer; }

.fc-widget {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
}

.fc-widget h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.1;
    color: #333333;
}

.fc-widget h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
    color: #333333;
}

.fc-widget .popover-title, .popover-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
}

.fc .popover h4 { margin: 10px 0; }

.fc-widget h3.text-muted { color: #999; }

.fc-widget .underline { border-bottom: 1px solid #dcdcdc; }

.fc-widget .body {
    margin: 5px;
    padding: 0 5px;
    font-size: 14px;
    line-height: 1.4em;
}

/* LOGIN */

.fc-widget .form-signin {
    max-width: 370px;
    padding: 60px 15px;
    margin: 0 auto;
}

.fc-widget .form-registration {
    max-width: 600px;
    padding: 30px 15px;
    margin: 0 auto;
}

.fc-widget .form-registration .panel, .form-signin .panel {
    -webkit-box-shadow: 0 2px 7px #000000;
    -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, .5);
    -ms-box-shadow: 0 2px 7px #000000;
    -ms-box-shadow: 0 2px 7px rgba(0, 0, 0, .5);
    box-shadow: 0 2px 7px #000000;
    box-shadow: 0 2px 7px rgba(0, 0, 0, .5);
}

.fc-widget .form-signin .form-signin-heading { margin-bottom: 10px; }

.fc-widget .form-signin .modal-body { padding-bottom: 0; }

.fc-widget .form-signin .modal-header { margin-top: 0; }

.fc-widget .form-signin .footer-links { margin-top: 10px; }

.fc-widget .form-signin .checkbox {
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
}

.fc-widget .form-signin .form-control {
    /*position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;*/
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fc-widget .form-signin .modal-footer .btn-link {
    padding: 2px;
    font-weight: bold;
}

.fc-widget .form-signin .form-control:focus { z-index: 2; }

.fc-widget .form-signin .first {
    margin-bottom: -1px;
    -ms-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -ms-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
}

.fc-widget .form-signin .last {
    margin-top: 0;
    margin-bottom: 10px;
    -ms-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -ms-border-top-right-radius: 0;
    border-top-right-radius: 0;
}

.fc-widget .form-signin .check-margin10 { margin-left: 10px; }

/* CART */

.fc-cart-avail-note {
    margin: 0;
    margin-left: 10px;
    padding: 5px;
    background: #337ab7;
    font-size: 12px;
    line-height: 1.15;
    text-align: center;
    color: #fff;
}

.fc-cart-avail-note-extra {
    margin: 0;
    margin-left: 70px;
    padding: 5px;
    font-size: 10px;
    line-height: 1.15;
    text-align: left;
    font-style: italic;
}

.fc-cart-avail-note-review {
    margin: 0;
    padding: 5px;
    font-size: 10px;
    line-height: 1.15;
    text-align: center;
    font-style: italic;
}

.fc-cart ul.footnotes { list-style: circle; }

.fc-cart .remove-button {
    padding-left: 0;
    font-size: 12px;
}

.fc-user-badge .glyphicon-user { padding-right: 5px; }

.fc-user-badge .gravatar {
    margin-right: 5px;
    top: -3px;
    position: relative;
    display: inline-block;
}

.fc-content .fc-content-manage { margin: 10px 0; }

.fc-content .fc-content-manage .fc-content-toolbar {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 5px 10px;
}

/*.fc-content .manage .fc-content-body {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}*/

.fc-content .html-only-textbox {
    width: 100%;
    min-height: 700px;
    max-height: 700px;
    padding: 16px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.45;
    border: 0;
    background-color: #f7f7f7;
    border-radius: 3px;
    margin: 0;
    word-break: normal;
    font: 14px Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.fc-user .btn-link { color: #428bca; }

.fc-user .btn-link:hover { color: #2a6496; }

.fc .right-inner-addon {
    position: relative;
    /*display: inline-block;*/
}

.fc .right-inner-addon input { padding-right: 30px; }

.fc .right-inner-addon i.fa,
.fc .right-inner-addon span.fa,
.fc .right-inner-addon i.glyphicon,
.fc .right-inner-addon span.glyphicon {
    position: absolute;
    right: 0px;
    padding: 10px 12px;
    pointer-events: none;
}

.fc .fc-select {
    width: 155px;
    display: inline-block;
    margin-left: 4px;
    color: #333;
}