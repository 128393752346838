.fc-mu-drop-zone {
    padding: 100px;
    text-align: center;
    border: 3px dashed #ccc;
    font-size: 18px;
    color: #888;
    cursor: pointer;
}

.panel-title {
    font-size: 18px;
    line-height: 35px;
}

img.fc-mu-thumbnail { height: 25px; }

i.fc-mu-thumbnail {
    font-size: 1.5em;
    line-height: 25px;
}

/*.fc-mu-upload {
    overflow-y: scroll;
}*/

ul.fc-mu-upload-files {
    margin: 0;
    padding: 0;
    -webkit-padding-start: 0;
    z-index: 2;
    border-bottom: 1px solid #ddd;
}

ul.fc-mu-upload-file.ng-hide {
    -ms-opacity: 0;
    opacity: 0;
}

ul.fc-mu-upload-file.ng-show {
    -ms-opacity: 1;
    opacity: 1;
}

.fc-mu-product-view { cursor: pointer; }

li.fc-mu-upload-file {
    list-style: none;
    border-top: 1px solid #ddd;
    text-align: left;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    position: relative;
    width: 100%;
    z-index: 2;
}

.fc-mu-upload-file input.form-control {
    margin-top: 5px;
    margin-right: 5px;
    width: 95%;
}

.dropdown-menu { z-index: 100; }

li.fc-mu-upload-file:after, li.fc-mu-upload-file:before {
    display: table;
    content: " ";
}

li.fc-mu-upload-file:after { clear: both; }

.fc-mu-upload-info:after, .fc-mu-upload-info:before {
    display: table;
    content: " ";
}

.fc-mu-upload-info:after { clear: both; }

.fc-mu-progress {
    height: 20px;
    width: 100%;
    overflow: hidden;
    background-color: #d9edf7;
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.fc-mu-progress-bar {
    background-color: #e9f4ff;
    position: relative;
    z-index: 1;
    height: 60px;
}

.fc-mu-progress-completed { background-color: #ebffd9; }

.fc-mu-progress-error { background-color: #f2dede; }

.modal-success { background-color: #ebffd9; }

.modal-danger { background-color: #f2dede; }


.fc-mu-upload-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    vertical-align: baseline;
    padding: 10px;
}

.fc-mu-upload-icon {
    float: left;
    width: 40px;
    vertical-align: baseline;
    -moz-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 5px;
}

.fc-mu-upload-file-name {
    float: left;
    width: 25%;
}

.fc-mu-upload-file-name .file-name {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}

.fc-mu-upload-file-name .file-size {
    display: inline-block;
    font-weight: normal;
    font-size: 11px;
    vertical-align: middle;
    padding: 0 3px;
    margin: -1px 0 0;
    border: 1px solid #aaa;
    text-align: center;
    color: #777;
    -ms-border-radius: 2px;
    border-radius: 2px;
}

.fc-mu-upload-title {
    float: left;
    height: 45px;
    width: 50%;
    -moz-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fc-mu-upload-type {
    float: left;
    height: 45px;
    width: 17%;
    -moz-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fc-mu-upload-status {
    float: left;
    text-align: left;
    width: 40px;
    padding-left: 5px;
    -moz-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fc-mu-upload-status .fa {
    font-size: 1.5em;
    line-height: 25px;
}

.fc-mu-upload-status .fa-times { font-size: 1.3em; }

.fc-mu-progress-arc {
    margin-top: 9px;
    display: inline-block;
}

.fc-mu-table td {
    padding: 5px 10px;
    border-top: 1px solid #ddd;
}

/****************/


.fc-mu-upload-product {
    float: right;
    width: 55%;
    padding: 0 10px;
    text-align: left;
}

.fc-mu-upload-file input.fc-mu-product-select {
    width: 100%;
    margin: 0;
}

.fc-mu-upload-assetType {
    position: absolute;
    left: 90px;
    top: 27px;
    width: 200px;
    float: none;
    cursor: pointer;
}

.fc-mu-assetType-view {
    color: #0000FF;
    /*text-decoration: underline;*/
    font-size: 12px;
}

.fc-mu-upload-file input.fc-mu-assetType-select {
    width: 100%;
    margin: 5px 0;
    height: 25px;
    font-size: 11px;
}

.metadata-title {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: block;
}

.metadata-format {
    color: #0000FF;
    /*text-decoration: underline;*/
    display: inline-block;
    font-size: 12px;
}

.metadata-domain {
    display: inline-block;
    vertical-align: middle;
    color: #31708f;
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    padding: 0 3px;
    margin: 0 2px;
    font-size: 11px;
    -ms-border-radius: 2px;
    border-radius: 2px;
}

.metadata-asset-exists {
    display: inline-block;
    vertical-align: middle;
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    padding: 0 3px;
    margin: 0 2px;
    font-size: 11px;
    -ms-border-radius: 2px;
    border-radius: 2px;
}

.dropdown-menu > .active > a > span.metadata-format,
.dropdown-menu > .active > a:hover > span.metadata-format,
.dropdown-menu > .active > a:focus > span.metadata-format { color: #ddd; }